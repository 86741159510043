import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import './UserTable.css'; 

const UserTable = ({ users, setSelectedUser }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Define the columns for the DataTable
    const columns = [
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: row => row.company_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Active',
            selector: row => (row.active ? 'Yes' : 'No'),
            sortable: true,
        },
        {
            name: 'Creation Date',
            selector: row => new Date(row.creation_datetime).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <button onClick={() => handleViewDetails(row)}>View Details</button>
            ),
        },
    ];

    const handleViewDetails = (user) => {
        setSelectedUser(user);
    };

    // Function to filter users based on the search term
    const filteredUsers = users.filter(user => {
        const { username, email, company_name, id } = user;
        return (
            username.toLowerCase().includes(searchTerm.toLowerCase()) ||
            email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            id.toString().includes(searchTerm)
        );
    });

    return (
        <div className='user-table-container'>
            <input
                type="text"
                placeholder="Search by name, ID, email, company name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-bar"
            />
            <DataTable
                title="User List"
                columns={columns}
                data={filteredUsers}
                pagination
            />
        </div>
    );
};

export default UserTable;
