import React, { useEffect } from 'react';
import './ConfirmationLottery.css';

function ConfirmationLottery({ settings }) {

    useEffect(() => {
        console.log(settings);
    }, [settings]);

    const renderSubValues = (subValues) => {
        return Object.keys(subValues).map((key) => {
            const value = subValues[key];

            if (Array.isArray(value)) {
                return (
                    <div key={key} className="sub-value">
                        <strong>{key}:</strong>
                        <div className="array-items">
                            {value.map((item, index) => (
                                <div key={index} className="array-item">
                                    {typeof item === 'object' ? renderSubValues(item) : item.toString()}
                                </div>
                            ))}
                        </div>
                    </div>
                );
            } else if (typeof value === 'object' && value !== null) {
                return (
                    <div key={key} className="nested-sub-value">
                        <strong>{key}:</strong>
                        <div className="nested-content">
                            {renderSubValues(value)}
                        </div>
                    </div>
                );
            } else {
                return (
                    value ? (
                        <div key={key} className="sub-value">
                            <strong>{key}:</strong> {value.toString()}
                        </div>
                    ) : null
                );
            }
        });
    };

    return (
        <div className="confirmation-lottery">
            {Object.keys(settings).filter((title) => title !== 'isLottery').map((title) => (
                <div key={title} className="section">
                    <h2 className="section-title">{settings[title].name || title}</h2>
                    <div className="section-content">
                        {renderSubValues(settings[title].subValues)}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ConfirmationLottery;
