import React, { useState } from 'react';
import { FaPaypal, FaCcVisa, FaCcMastercard } from 'react-icons/fa';
import './PaymentCard.css'

const PaymentCard = ({ basePrice , createOrder }) => {
  const [duration, setDuration] = useState(3);  // default to 3 months
  const [discount, setDiscount] = useState(0);  // default no discount

  const handleDurationChange = (event) => {
    const selectedDuration = parseInt(event.target.value, 10);
    setDuration(selectedDuration);

    // Apply discount based on duration
    if (selectedDuration === 6) {
      setDiscount(10);  // 10% discount for 6 months
    } else if (selectedDuration === 12) {
      setDiscount(20);  // 20% discount for 12 months
    } else {
      setDiscount(0);   // No discount for 3 months
    }
  };

  const calculatePrice = () => {
    const totalPrice = basePrice * duration;
    const discountAmount = (totalPrice * discount) / 100;
    return totalPrice - discountAmount;
  };

  return (
    <div className="payment-card">
      <h2>Subscription Plan</h2>

      <div className="duration-selector">
        <label htmlFor="duration">Choose Duration:</label>
        <select id="duration" value={duration} onChange={handleDurationChange}>
          <option value={3}>3 Months</option>
          <option value={6}>6 Months</option>
          <option value={12}>12 Months</option>
        </select>
      </div>

      <p>Discount: {discount}%</p>
      <p>Total Price: ${calculatePrice().toFixed(2)}</p>

      <div className="payment-methods">
        <h3>Pay with:</h3>
        <FaPaypal onClick={() => createOrder(duration)} size={40} />
        <a href="https://visa.com" target="_blank" rel="noopener noreferrer">
          <FaCcVisa size={40} />
        </a>
        <a href="https://mastercard.com" target="_blank" rel="noopener noreferrer">
          <FaCcMastercard size={40} />
        </a>
      </div>
    </div>
  );
};

export default PaymentCard;
