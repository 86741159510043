import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './GenModal.css';


function GenModal({ visible, title, sub_title, text, buttons, children, onClose , setModalInterface }) {

    function handleClose(){
        setModalInterface(prev => ({
            ...prev,
            visible: false,
            title: null,
            description: null,
            buttons: null,
            children: null,
        }))
    }

  return (
    <Modal
      show={visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => handleClose()} 
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sub_title && <h4>{sub_title}</h4>}
        {text && <p>{text}</p>}
        {/* Render children if provided */}
        {children && <div>{children}</div>}
      </Modal.Body>
      <Modal.Footer>
        {buttons && buttons.map((button, index) => (
          <Button 
            key={index} 
            onClick={button.onclick} 
            variant={button.variant || 'primary'}
            style={{ margin: '0 5px' }} // Spacing between buttons
          >
            {button.text}
          </Button>
        ))}
        <Button variant="secondary" onClick={() => handleClose()
        }>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GenModal;
