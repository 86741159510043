import React, { useEffect } from 'react'
import './DataCollector.css'
import api from '../api'
import { closeModal, createModal } from '../../mobx/ModalInterface';

function DataCollector({activeGame , setActiveGame , ...props}) {

    async function downloadUserList() {
        try {

            const res = await api.post('/data_collector/download_user_list', {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id
            }, {
                responseType: 'blob'
            });
    
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
    
            const contentDisposition = res.headers['content-disposition'];
            const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : 'user_list.csv';
            link.download = fileName;
    
            document.body.appendChild(link);
            link.click();
    
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
    
        } catch (e) {
            console.error('Error downloading file:', e);
        }
    }
    
    async function downloadLeaderboard() {
        try {

            const res = await api.post('/data_collector/download_leaderboard', {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id
            }, {
                responseType: 'blob'
            });

            console.log(res);
            
    
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
    
            const contentDisposition = res.headers['content-disposition'];
            const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : 'leaderboard.csv';
            link.download = fileName;
    
            document.body.appendChild(link);
            link.click();
    
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
    
        } catch (e) {
            console.error('Error downloading file:', e);
        }
    }

    async function resetLeaderboard(){
        try {
            const res = await api.post('/data_collector/reset_leaderboard', {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id
            })
            console.log(res);
            
        } catch (e) {
            console.log(e);
            
        }
    }

    async function previewLeaderboard(){
        try {
            const res = await api.post('/data_collector/preview_leaderboard', {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id
            })
            if(res.data.status){
                alert(JSON.stringify(res.data.leaderboard))
            }
            else if (res.status === 204){
                alert("Leaderboard's list empty")
            }
            console.log(res);
            
        } catch (e) {
            console.log(e);
            
        }
    }

    async function restoreLatestVersion(){
        try {   
            const res = await api.post('/data_collector/restore_latest_game_version', {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id
            })
            if(res.data.status){
                alert("Game restored successfully")
            } 
        } catch(e) {
            console.log(e);
            
        }
    }

    function createActivationModal(){
        const title = activeGame ? "Deactivate Game" : "Activate Game"
        const desc = `Are you sure you want to ${title}?`
        const btns = [{text: 'Yes' , onclick: () => deactivateGame() } ]
        createModal(props.setModalInterface, title , desc , btns , null)
    }

    async function deactivateGame(){
        try {
            const response = await api.post('/data_collector/change_game_active_status' , {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id
            })
            if(response.data.status){
                closeModal(props.setModalInterface)
                setActiveGame(response.data.active)
                alert("Game actication has been changed!")
            }
            console.log(response);
            
        } catch (e) {
            console.log(e);
            
        }
    }

    useEffect(() => {
        console.log(props);
        
    },[])

    
  return (
    <div className='data-collector'>
        <button type='button' onClick={() => downloadUserList()}>Download User List</button>
        <button type='button' onClick={() => downloadLeaderboard()}>Download Table Of Winners</button>
        <button type='button' onClick={() => previewLeaderboard()}>Preview Table Of Winners</button>
        <button type='button' onClick={() => resetLeaderboard()}>Reset Table Of Winners</button>
        <button type='button' onClick={() => restoreLatestVersion()}>Restore Latest Version</button>
        <button style={{backgroundColor: activeGame ? null : 'red'}} type='button' onClick={() => createActivationModal()}>Deactivate Game</button>
    </div>
  )
}

export default DataCollector