function createModal(setModalInterface , title , description , buttons , children){
   setModalInterface(prev => ({
        ...prev,
        visible: true,
        title: title,
        text: description,
        buttons: buttons,
        children: children
    }))
}

function closeModal(setModalInterface){
    setModalInterface(prev => ({
        ...prev,
        visible: false,
        title: null,
        text: null,
        buttons: null,
        children: null
    }))
}

export { createModal, closeModal };