import React, { useEffect , useState} from 'react'
import SettingsPhase from './GamePhase/SettingsPhase';
import nav from '../utils/Navigation';
import PhaseSelector from '../components/PhaseSelector/PhaseSelector';
import MusicPhase from './GamePhase/MusicPhase';
import GameAssetsPhase from './GamePhase/GameAssetsPhase';
import ConfirmationPhase from './GamePhase/ConfirmationPhase';
import PaymentPhase from './GamePhase/PaymentPhase';
import OverviewPhase from './GamePhase/OverviewPhase';
import gameSettings from '../const/Settings';
import api from '../components/api'
import './Screens.css'
import { IoMdHelp } from 'react-icons/io';
import path from 'path-browserify';

function ScreenGamePhase(props) {
    const [settings, setSettings] = useState(gameSettings[props.appDetails.currentGame]);
    const [gameName , setGameName] = useState("")
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewFiles , setPreviewFiles] = useState([])
    const [listOfFiles , setListOfFiles] = useState([])
    const [listOfMusicFiles , setListOfMusicFiles] = useState([])
    const [gameTemplates , setGameTemplates] = useState([])
    const [selectedTemplate , setSelectedTemplate] = useState(null)
    const [gameURL, setGameURL] = useState()
    const [paidPlan , setPaidPlan] = useState(false)
    const [activeGame , setActiveGame] = useState(false)
    const [expirationDate , setExpirationDate] = useState(new Date())


    async function createGame(){
        try {
            const data = new FormData()
            data.append("settings" , JSON.stringify(settings))
            Object.values(selectedFiles).forEach(file => {
                data.append('files', file);
              });
            data.append("game_name", gameName)
            data.append("user" , JSON.stringify(props.userDetails))
            data.append("selected_game" , props.appDetails.currentGame)
            data.append('user_game' , JSON.stringify(props.appDetails))
            data.append('selected_template' , selectedTemplate)
            
            const res = await api.post('/create_game', data)
            console.log(res.data);
            if(!res.data.status){
                alert(res.data.msg)
                return
            }
            if(res.data.type === 'update'){
                alert("Game has updated and backup successfully")
            }
            if(res.data.currentGame){
                props.setAppDetails(prev => ({
                    ...prev,
                    game_id: res.data.currentGame[0].id,
                    settings: res.data.currentGame[0].settings

                }))
                setGameURL(res.data.ip)
                alert("Game created successfully")
            }
        } catch (e) {
            console.log(e);
        }
        
        // Handle form submission logic
    };

    const handleFilesChange = (event) => {
        const newFiles = Array.from(event.target.files);

        setSelectedFiles((prevFiles) => {
            // Ensure prevFiles is an array
            const currentFiles = Array.isArray(prevFiles) ? prevFiles : [];

            // Filter out files that have the same name as the new ones
            const updatedFiles = currentFiles.filter(
                (file) => !newFiles.some((newFile) => newFile.name === file.name)
            );

            // Add the new files
            return [...updatedFiles, ...newFiles];
        });
    };

    const handleFileChange = (event) => {
        console.log(previewFiles);
        
        const file = event.target.files[0];
        const inputName = event.target.name;
    
        // Set the uploaded file name to match the input field name
        const renamedFile = new File([file], inputName, { type: file.type });
        const fileName = path.basename(inputName.replace(/\\/g, '/'))
        
        setPreviewFiles((prevFiles) => ({
            ...prevFiles,
            [fileName]: {
              file: event.target.files[0],
              previewUrl: URL.createObjectURL(event.target.files[0]), 
            }
          }));
    
        setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [inputName]: renamedFile
        }));

        
        
    };

    function changePhase(phase){
        if(phase === 6 && !paidPlan){
            alert("You must purchase a plan to go to overview")
            return
        }
        props.setAppDetails(prev => ({
            ...prev,
            maxPhase: phase > props.appDetails.maxPhase ? phase : props.appDetails.maxPhase,
            currentPhase: phase
        }))
    }

    function handleBackPress(){
        props.setAppDetails(prev => ({
            ...prev,
            maxPhase: 1,
            currentPhase: 1,
            game_id: null,
            settings: null,
            currentGame: null

        }))
        nav.changeScreen(props.setPageDetails , "gameSelect")
    }

    async function getGameDefaultFiles(){
        try {
             const res = await api.post("/get_game_files", 
                {
                    game_type: props.appDetails.currentGame,
                    game_id: props.appDetails.game_id ? props.appDetails.game_id : null,
                    username: props.userDetails.username
                }
             )
             if(res.data.status){
                const files = res.data.files
                const musicTypes = ['.mp3', '.wav', '.ogg', '.flac']; // Add more music file types as needed
                const imageTypes = ['.jpg', '.jpeg', '.png', '.gif', '.bmp' , '.xlsx']; // Add more image file types as needed
        
                const musicFiles = [];
                const imageFiles = [];

                files.forEach((file) => {
                    const fileExtension = file.substring(file.lastIndexOf('.')).toLowerCase();
                    if (musicTypes.includes(fileExtension)) {
                        musicFiles.push(file);
                    } else if (imageTypes.includes(fileExtension)) {
                        imageFiles.push(file);
                    }
                });
                
                setListOfMusicFiles(musicFiles);
                setListOfFiles(imageFiles);
             }
        } catch (e) {
            console.log(e);
        }
    }

    async function getGameTemplates(){
        try {
            const res = await api.post('/get_game_templates', {
                game_type: props.appDetails.currentGame
            })
            setGameTemplates(res.data.gameTemplates)
            console.log(res.data);
            
        } catch (e) {
            console.log(e);
            
        }
    }

    async function loadExistingGame(){
        if(!props.appDetails.game_id){
            return
        }
        try {
            const res =  await api.post('/get_user_game_details' , {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id
            })
            console.log(res.data.game);
            
            if(!res.data.status){
                //Failure
            } else {
                setGameName(res.data.game.game_name)
                setGameURL(res.data.game.ip)
                setPaidPlan(res.data.game.paid)
                setExpirationDate(res.data.game.expiration_datetime)
                setSettings(JSON.parse(res.data.game.settings))
                setActiveGame(res.data.game.active)
                
            }
            console.log(res.data);
            
        } catch (e) {
            
            console.log(e);
            
        }
    }

    async function createOrder(duration){
        try {
            const response = await api.post('/paypal/pay', {
                user_id: props.userDetails.id,
                game_id: props.appDetails.game_id,
                game_type: props.appDetails.currentGame,
                duration: duration
            })
            if(!response.data.status){
                return
            } 

            const paymentUrl = response.data.url

            window.location.href = paymentUrl;
            
        } catch (e) {
            console.log(e);
            
        }
    }

    function userManual() {
        const link = document.createElement('a');
        link.href = `/${props.appDetails.currentGame}.pdf`; 
        link.target = '_blank'; 
        link.download = `${props.appDetails.currentGame}.pdf`; 
        document.body.appendChild(link);
        link.click(); 
        document.body.removeChild(link); 
      }


    useEffect(() => {
        getGameDefaultFiles()
        getGameTemplates()
    },[props.pageDetails.gameDetails])

    useEffect(() => {
        loadExistingGame()
    }, [props.appDetails.game_id])

    useEffect(() => {
        if(paidPlan){
            changePhase(6)
        } 
        else if(!paidPlan && props.appDetails.game_id){
            changePhase(5)
        }
    },[paidPlan])

  return (
    <div>
        <button className='back-btn' onClick={() => handleBackPress()}>Back</button>
        
        <PhaseSelector paidPlan={paidPlan} {...props} />
        <div className='manual'>
            <IoMdHelp size={60} onClick={() => userManual()} cursor={'pointer'}/>
            <a href={`https://theminigame.com/demo/${props.appDetails.currentGame}`} target={"_blank"}>Click here to visit the demo game</a>
        </div>
        <div className='phase-container'>
        
            {props.appDetails.currentPhase === 1 ? <SettingsPhase {...props} changePhase={changePhase} currentGame={props.appDetails.currentGame} settings={settings} setSettings={setSettings} handleFileChange={handleFileChange} gameName={gameName} setGameName={setGameName} /> : null}
            {props.appDetails.currentPhase === 2 ? <MusicPhase previewFiles={previewFiles} changePhase={changePhase} listOfMusicFiles={listOfMusicFiles} handleFileChange={handleFileChange} /> : null}
            {props.appDetails.currentPhase === 3 ? <GameAssetsPhase templates={gameTemplates} selectedTemplate={selectedTemplate} previewFiles={previewFiles}  setSelectedTemplate={setSelectedTemplate} changePhase={changePhase} listOfFiles={listOfFiles} handleFileChange={handleFileChange} handleFilesChange={handleFilesChange} selectedFiles={selectedFiles} setListOfFiles={setListOfFiles} currentGame={props.appDetails.currentGame} {...props} /> : null}
            {props.appDetails.currentPhase === 4 ? <ConfirmationPhase selectedTemplate={selectedTemplate} userDetails={props.userDetails} changePhase={changePhase} createGame={createGame} gameName={gameName} settings={settings} appDetails={props.appDetails} /> : null}
            {props.appDetails.currentPhase === 5 ? <PaymentPhase changePhase={changePhase} paidPlan={paidPlan} createOrder={createOrder} expirationDate={expirationDate} /> : null}
            {props.appDetails.currentPhase === 6 ? <OverviewPhase {...props} gameURL={gameURL} activeGame={activeGame} setActiveGame={setActiveGame} /> : null}
        </div>
       
    </div>
  )
}

export default ScreenGamePhase