const utils = {
    formatDate: function formatDate(dateString) {
        const options = {
            year: 'numeric',
            month: 'long', // or '2-digit' for numeric month
            day: 'numeric',

        };
        
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
    }
}

export default utils