import React, { useState } from 'react';
import './SearchComponent.css'
import GameCard from '../GameCard/GameCard';

const SearchComponent = ({ data , ...props }) => {
  const [query, setQuery] = useState('');

  const handleSearch = (event) => {
    setQuery(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className='search-component-container'>
      <input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={handleSearch}
      />
      <ul className='search-component-data'>
        {filteredData.map((item, index) => (
          <GameCard key={index} gameName={item} {...props} />
        ))}
      </ul>
    </div>
  );
};

export default SearchComponent;
