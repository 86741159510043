import React, { useEffect , useState} from 'react'
import api from '../components/api'
import './Screens.css'
import nav from '../utils/Navigation';
import { FaRegEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";
import ReferUserTable from '../components/ReferUserTable/ReferUserTable';

function ScreenSettings(props) {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isVPasswordVisible, setIsVPasswordVisible] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        validatePassword: '',
        companyName: '',
        email: ''
    });
    
    const [errors, setErrors] = useState({});
    const [referedUsers , setReferedUsers] = useState([])
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

      const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
      };

      const toggleVPasswordVisibility = () => {
        setIsVPasswordVisible(prevState => !prevState);
      };
    
      const validateForm = () => {
        let formErrors = {};
    
        if (formData.password !== formData.validatePassword) {
          formErrors.validatePassword = "Passwords do not match.";
        }
    
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (validateForm()) {
          try {
            const res = await api.post('/auth/update_user', {
                user_id: props.userDetails.id,
                username: formData.username,
                password: formData.password,
                vpassword: formData.validatePassword,
                company_name: formData.companyName,
                email: formData.email
            })

            if(res.data.status){
                alert("User details updated successfully!")
                props.setUserDetails(prev => ({
                    ...prev,
                    id: res.data.user[0].id,
                    username: res.data.user[0].username,
                    email: res.data.user[0].email,
                    companyName: res.data.user[0].company_name
                }))
                nav.changeScreen(props.setPageDetails , "gameSelect")
            } else {
                alert(res.data.msg)
            }
            console.log(res);
            
          } catch (e) {
            console.log(e);
            
          }
          console.log("Form submitted successfully:", formData);
        }
      };

    function handleBackPress(){
        nav.changeScreen(props.setPageDetails , "gameSelect")
    }

    async function getRefLink(){
      try {
        const response = await api.post('/auth/get-ref-link',{
          user_id: props.userDetails.id
        })

        if(response.data.status){
          props.setUserDetails(prev => ({
            ...prev,
            ref: response.data.link
          }))
        } else {
          alert(response.data.msg)
        }
        console.log(response);
        
      } catch (e) {

      }
    }

    async function getReferdUsers(){
      try {
        const res = await api.post('/auth/get_users_refs', {
          user_id: props.userDetails.id
        })
        if(res.data.status){
          setReferedUsers(res.data.referedUsers)
        }
      } catch (e) {
        console.log(e);
        
      }
    }

    const copyToClipboard = () => {
      if (props.userDetails.ref) {
        navigator.clipboard.writeText(props.userDetails.ref)
          .then(() => alert("Reference link copied to clipboard!"))
          .catch((err) => console.error("Failed to copy text: ", err));
      }
    };

    useEffect(() => {
      getReferdUsers()
        
    },[])
  return (
    <div >
      <button className='back-btn' onClick={() => handleBackPress()}>Back</button>
      <h2>Update Your Information</h2>
      <form className="form-container" onSubmit={handleSubmit}>
        

        <div className="form-group">
          <label>Password</label>
          <div className='password-form'>
            <input
              type={isPasswordVisible ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={errors.password ? 'input-error' : ''}
            />
            {isPasswordVisible ? <FaEyeSlash onClick={() => togglePasswordVisibility()} size={25}/> : <FaRegEye onClick={() => togglePasswordVisibility()} size={25} />}
          </div>
          
          {errors.password && <p className="error">{errors.password}</p>}
        </div>

        <div className="form-group">
          <label>Validate Password</label>
          <div className='password-form'>
            <input
              type={isVPasswordVisible ? "text" : "password"}
              name="validatePassword"
              value={formData.validatePassword}
              onChange={handleChange}
              className={errors.validatePassword ? 'input-error' : ''}
            />
            {isVPasswordVisible ? <FaEyeSlash onClick={() => toggleVPasswordVisibility()} size={25}/> : <FaRegEye onClick={() => toggleVPasswordVisibility()} size={25} />}
          </div>
          
          {errors.validatePassword && <p className="error">{errors.validatePassword}</p>}
        </div>

        <div className="form-group">
          <label>Company Name</label>
          <input
            type="text"
            name="companyName"
            placeholder={props.userDetails.companyName}
            value={formData.companyName}
            onChange={handleChange}
            className={errors.companyName ? 'input-error' : ''}
          />
          {errors.companyName && <p className="error">{errors.companyName}</p>}
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder={props.userDetails.email}
            value={formData.email}
            onChange={handleChange}
            className={errors.email ? 'input-error' : ''}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>

        <button type="submit" className="submit-button">Update</button>
      </form>
      <div className='ref-container'>
        <h2>Ref Link</h2>
        {props.userDetails.ref && (
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <p style={{marginRight: '10px'}}>Your Reference Link: </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{props.userDetails.ref}</span>
            <button onClick={copyToClipboard} style={{ marginLeft: '10px' }}>
              Copy
            </button>
          </div>
        </div>
      )}
        <div>
          <button onClick={() => getRefLink()}>Get Ref Link</button>
        </div>
        <ReferUserTable users={referedUsers} />
      </div>
    </div>
  )
}

export default ScreenSettings