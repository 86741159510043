import React, { useEffect } from 'react'
import Cookies from 'js-cookie';
import './Phases.css'
import ConfirmationLottery from '../../components/Confirmations/ConfirmationLottery';

function ConfirmationPhase({changePhase , gameName , settings , appDetails , createGame , userDetails , selectedTemplate}) {
    const token = Cookies.get('access-token');
    useEffect(() => {
        console.log("Settings" , settings);
        console.log(token);
        
        
    },[])
  return (
    <div className='phases-container'>
        <h2>Confirmation</h2>
        <div>
            <h3>General</h3>
            <label>Game Name: {gameName}</label>
            <label>Game Type: {appDetails.currentGame}</label>
            <label>Game ID: {appDetails.game_id}</label>
            <label>Selected Template: {selectedTemplate}</label>
        </div>
        <div>
            <h3>Settings</h3>
            <div>
    {settings.isLottery ? <ConfirmationLottery settings={settings} /> : Object.keys(settings).map(title => {
        return (
            <div key={title}>
                <label>{title}</label>
                {Object.keys(settings[title].subValues).map(key => {
                    const value = settings[title].subValues[key];
                    console.log(value);

                    return (
                            <div key={key}>
                                {value ? (
                                    <div>
                                        {key} : {Array.isArray(value) ? value[0] : value.toString()}
                                    </div>
                                ) : null}
                            </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
        <div className='confirmation-buttons'>
            {appDetails.game_id ? 
            <div className='url-holder'>
                
                <a href={`https://theminigame.com/preview/?gi=${appDetails.game_id}`} target={"_blank"}>Click here to visit the preview game</a>
            </div> : null}
            {appDetails.game_id ? <button onClick={() => createGame()} >Update</button> : null}
            {appDetails.game_id ? <button onClick={() => changePhase(5)}>Next</button> : <button onClick={() => createGame()}>Create Game</button>}
        </div>
        
        
    </div>
  )
}

export default ConfirmationPhase