import React, { useEffect, useState } from 'react'
import nav from '../utils/Navigation'
import api from '../components/api'
import UserTable from '../components/UserTable/UserTable'
import UserPreview from '../admin_components/UserPreview/UserPreview'

function ScreenAdminPanel(props) {
    const [selectedUser , setSelectedUser] = useState(null)
    const [selectedUserGames , setSelectedUserGames] = useState()
    const [userList , setUserList] = useState([])

    function handleBackPress(){
        nav.changeScreen(props.setPageDetails , "gameSelect")
    }

    async function getUserList(){
        try {
            const res = await api.post('/admin/get_user_list',
                {
                    user_id: props.userDetails.id
                }
            )
            if(res.data.status){
                setUserList(res.data.userList)
            }
            console.log(res);
            
        } catch (e) {
            console.log(e);
            
        }
    }

    async function getSelectedUserGames(){
        
        try {
            const res = await api.post('/admin/get_user_games',
                {
                    user_id: props.userDetails.id,
                    selected_user: selectedUser.id
                }
            )
            if(res.data.status){
                setSelectedUserGames(res.data.userGames)
            }
            console.log(res);
            
        } catch (e) {
            console.log(e);
            
        }
    }

    useEffect(() => {
        getUserList()
    },[])

    useEffect(() => {
        getSelectedUserGames()
    },[selectedUser ])

  return (
    <div>
        <button className='back-btn' onClick={() => handleBackPress()}>Back</button>
        <div className='admin_panel_container'>
            <UserTable users={userList} setSelectedUser={setSelectedUser} />
            <div className='ap_user_games'>
                {selectedUserGames ? <UserPreview {...props} selectedUser={selectedUser} selectedUserGames={selectedUserGames} getSelectedUserGames={getSelectedUserGames}/> : null }
            </div>
            
            
        </div>
       
    </div>
  )
}

export default ScreenAdminPanel