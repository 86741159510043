import React, { useEffect } from 'react';
import './GameCard.css';
import nav from '../../utils/Navigation';

function GameCard({ gameName , id , settings, ...props } ,) {
    function handleClick(){
        
        props.setAppDetails(prev => ({
            ...prev,
            game_id: id ? id : null,
            settings: settings ? settings : null,
            currentGame: gameName
        }))
        console.log("running");
        nav.changeScreen(props.setPageDetails , "gamePhase")
    }

    return (
        <div className="game-card" onClick={() => handleClick()}>
            <h3 className="game-card-title">{`${String(gameName).toUpperCase()} ${id ? id : ''}`}</h3>
        </div>
    );
}

export default GameCard;
