import React, { useEffect, useState } from 'react';
import MusicPlayer from '../../components/MusicPlayer/MusicPlayer';

function MusicPhase({ changePhase, handleFileChange, listOfMusicFiles , previewFiles}) {
    const [selectedTrack, setSelectedTrack] = useState('');
    const [selectedTrackName, setSelectedTrackName] = useState('');

    const handleSelectTrack = (file) => {
      setSelectedTrack(`${process.env.REACT_APP_SERVER_URL}/${file}`);
      setSelectedTrackName(file.split('\\').pop()); // Store just the file name for display
    };

    useEffect(() => {
        if (listOfMusicFiles.length > 0) {
            // Set the first music asset as the selected track when the component mounts
            setSelectedTrack(`${process.env.REACT_APP_SERVER_URL}/${listOfMusicFiles[0]}`);
        }
    }, [listOfMusicFiles]);


    return (
        <div className="phases-container">
            <h2>Music Assets</h2>

            {selectedTrack && <MusicPlayer track={{src: selectedTrack }} />}

            <div className="track-selection">
                <div>
                    <label htmlFor="track-select">Select Track:</label>
                    {listOfMusicFiles.map((file, index) => (
                    <div key={index} className="track-container">
                        <h3>{file.split('\\').pop()}</h3> {/* Display the file name */}
                        <input 
                            name={file.split('\\').pop()}
                            type="file" 
                            accept="audio/*" 
                            onChange={(handleFileChange)} 
                        />
                        <button onClick={() => handleSelectTrack(file)}>Select Track</button>
                    </div>
                ))}
                </div>
            </div>


            <button onClick={() => changePhase(3)}>Next</button>
        </div>
    );
}

export default MusicPhase;
