import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaPause, FaStepBackward, FaStepForward, FaVolumeUp } from 'react-icons/fa';
import './MusicPlayer.css';

const MusicPlayer = ({ track }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1); // Volume ranges from 0 to 1

    useEffect(() => {
        if (isPlaying && audioRef.current) {
            audioRef.current.play();
        } else {
            audioRef.current?.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        console.log(track);
        
        if (audioRef.current) {
            audioRef.current.src = track.src;
            setIsPlaying(false); // Reset play state when the track changes
        }
    }, [track]);

    const handlePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };

    // Function to extract title from the src
    const extractTitle = (src) => {
        const fileNameWithExt = src.split('\\').pop(); // Get the last part of the path using backslash as separator
        return fileNameWithExt.split('.').slice(0, -1).join('.'); // Remove the file extension
    };

    const trackTitle = track && track.src ? extractTitle(track.src) : 'No track available';

    // Handle volume change
    const handleVolumeChange = (e) => {
        const newVolume = e.target.value;
        setVolume(newVolume);
        if (audioRef.current) {
            audioRef.current.volume = newVolume;
        }
    };

    return (
        <div className="track-player">
            <h2>{trackTitle}</h2>
            <audio ref={audioRef} />
            <div className="controls">

                <button onClick={handlePlayPause} className="control-btn">
                    {isPlaying ? <FaPause /> : <FaPlay />}
                </button>
  
            </div>
            <div className="volume-control">
                <FaVolumeUp />
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={handleVolumeChange}
                    className="volume-slider"
                />
            </div>
        </div>
    );
};

export default MusicPlayer;
