import React, { useEffect } from 'react'
import PaymentCard from '../../components/PaymentCard/PaymentCard'
import utils from '../../utils/utils';

function PaymentPhase({paidPlan , createOrder , changePhase , expirationDate}) {

  return (
    <div className='phases-container'>
        <h2>Payment</h2>
        <div>
            {paidPlan ? 
            <div>
                <label>Expiration Date: {utils.formatDate(expirationDate.toString())}</label>
                <button>Extend Membership</button>
                <button onClick={() => changePhase(6)}>Next</button>
            </div> : 
            <div>
                <PaymentCard basePrice={10} createOrder={createOrder} />
                {/* <button type='button' onClick={() => createOrder()}>Buy full game</button> */}
            </div>}
            
        </div>
    </div>
  )
}

export default PaymentPhase