import path from 'path-browserify'
import React , {useState } from 'react'
import api from '../../components/api'
import './Phases.css'
import {createModal} from '../../mobx/ModalInterface'
import { observable } from 'mobx'
import GameTemplateCard from '../../components/GameTemplateCard/GameTemplateCard'

function GameAssetsPhase({listOfFiles , templates , selectedFiles , handleFileChange , setListOfFiles, handleFilesChange ,previewFiles , changePhase , currentGame , selectedTemplate , setSelectedTemplate , ...props}) {
    const [bulkUpload , setBulkUpload] = useState(false)

    async function downloadGameFiles() {
        try {
            const res = await api.post("/download_game_files", {
                game_type: currentGame
            }, {
                responseType: 'blob' // Important to receive the response as a blob
            });
    
            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([res.data]));
    
            // Create a link element
            const link = document.createElement('a');
            link.href = url;
    
            // Set the download attribute to specify the filename
            link.setAttribute('download', `${currentGame}_assets.zip`);
    
            // Append the link to the body (required for Firefox)
            document.body.appendChild(link);
    
            // Programmatically click the link to trigger the download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
    
        } catch (e) {
            console.log(e);
        }
    }

    function onImageClick(){
        createModal(props.setModalInterface , "Image Description" , "Testing")
        
    }

    function getDisplay(){
        if(selectedTemplate){
            return 'none'
        } else {
            return 'flex'
        }
    }


  return (
    <div className='phases-container'>
        <h2>Game Assets</h2>
       
        <div className='template-container'>
            {templates && templates.map((template , key) => {
                return (
                    <GameTemplateCard handleFileChange={handleFileChange} setSelectedTemplate={setSelectedTemplate} setListOfFiles={setListOfFiles} selectedTemplate={selectedTemplate} key={key} templateName={template.template_name} images={template.listOfFiles} />
                )
            })}
        </div>  

        <h3>Upload My Own Assets</h3>
        
        <div className="files-container">
                <label>
                    Bulk upload
                    <input type='checkbox' checked={bulkUpload} onChange={() => setBulkUpload(prev => !prev)}></input>
                </label>
                   <button type='button' onClick={() => downloadGameFiles()}>Download Game Files</button>
                    {bulkUpload ? <input type='file' onChange={handleFilesChange} multiple></input> : 
                    <div className='img-container'>
                    {listOfFiles.length > 0 ? listOfFiles.map((file , key) => {  
                        const fileName = path.basename(file.replace(/\\/g, '/'))
                    return (
                        <div key={key} className='img-holder'>
                            {String(file).includes(".wav") || String(file).includes(".txt") ? null :  <img onClick={() => onImageClick()}  src={previewFiles[fileName]?.previewUrl || `${process.env.REACT_APP_SERVER_URL}/${file}`} /> }
                           
                            <label>{path.basename(file.replace(/\\/g, '/'))}</label>
                            <input 
                            type="file" 
                            onChange={handleFileChange} 
                            name={path.basename(file)}
                            accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/*" 
                            />
                        </div>
                    )
                    }) : null}
                </div>
                    }

        </div>
        
        <button onClick={() => changePhase(4)}>Next</button>
    </div>
  )
}

export default GameAssetsPhase