import {useEffect, useState } from 'react'
import './App.css';
import ScreenLogin from './screens/ScreenLogin';
import ScreenRegister from './screens/ScreenRegister';
import ScreenGameSelect from './screens/ScreenGameSelect';
import ScreenGameDetails from './screens/ScreenGameDetails';
import api from './components/api'
import ScreenOtp from './screens/ScreenOtp';
import ScreenSettings from './screens/ScreenSettings';
import nav from './utils/Navigation';
import ScreenGamePhase from './screens/ScreenGamePhase';
import GenModal from './components/GenModal/GenModal';
import ScreenAdminPanel from './screens/ScreenAdminPanel';
import Cookies from "js-cookie"

function App() {
  const [userDetails , setUserDetails] = useState({
    id: null,
    username: null,
    email: null,
    companyName: null,
    accessLevel: 1,
    ref: null
  })

  const [appDetails , setAppDetails] = useState({
    maxPhase: 1,
    currentPhase: 1,
    game_id: null,
    settings: null,
    currentGame: null
  })

  const [pageDetails , setPageDetails] = useState({
    register: false,
    login: true,
    otp: false,
    gameSelect: false,
    gameDetails: false,
    gamePhase: false,
    settings: false,
    admin: false,
  })
  const [modalInterface , setModalInterface] = useState({
    visible: false,
    title: '',
    text: '',
    buttons: [],
    children: null,
  })


  async function hanldeAutoLogin(){
    try {
      const res = await api.post('/auth/auto_login')
      
      if(res.data.status){
        setUserDetails(prev => ({
          ...prev,
          id: res.data.user[0].id,
          username: res.data.user[0].username,
          email: res.data.user[0].email,
          companyName: res.data.user[0].company_name,
          accessLevel: res.data.user[0].access_level,
          ref: res.data.user[0].ref
      }))
      nav.changeScreen(setPageDetails, "gameSelect")
      }
    } catch (e) {

    }
  }

  async function handleParams(){

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const token = params.get('token'); // Replace 'param1' with your actual parameter name
    const payerId = params.get('PayerID'); // Replace 'param2' with your actual parameter name

    if(!token && !payerId){
      return
    }

    try {
      const response = await api.post('/paypal/validate_order', {
        order_id: token
      })
      console.log(response);
      console.log(response.data.gameDetails);
      
      if(response.data.status){
        if(response.data.gameDetails){
          setAppDetails(prev => ({
            ...prev,
            settings: JSON.parse(response.data.gameDetails[0].settings),
            game_id:  response.data.gameDetails[0].id,
            currentGame: response.data.gameDetails[0].game_type
          }))
  
          nav.changeScreen(setPageDetails, "gamePhase")
        }
        
      }
      alert(response.data.msg)
      
    } catch (e) {
      console.log(e);
      
    }
    
    
  }

  async function handleRef(){
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const ref = params.get('ref')

    const accessToken = Cookies.get('access-token');
    console.log(accessToken);
    
    if(accessToken || !ref){
      return
    }

    try {
      const res = await api.post('/auth/validate_ref_token' , {
        ref: ref
      })
      if(res.data.status){
        setPageDetails(prev => ({
          ...prev,
          register: true,
          login: false
        }))
      } else {
        alert(res.data.err)
      }
    } catch (e) {
      console.log(e);
      
    }
  }

  

  useEffect(() => {
    hanldeAutoLogin()
    handleParams()
    handleRef()
  },[])
  
  return (
    <div className="App">
     
          {pageDetails.login ? <ScreenLogin setModalInterface={setModalInterface} visible={pageDetails.login} setUserDetails={setUserDetails} pageDetails={pageDetails} setPageDetails={setPageDetails} /> : null }
          {pageDetails.otp ? <ScreenOtp visible={pageDetails.login} setModalInterface={setModalInterface} setUserDetails={setUserDetails} userDetails={userDetails} pageDetails={pageDetails} setPageDetails={setPageDetails}/> : null }
          {pageDetails.register ? <ScreenRegister visible={pageDetails.register} setModalInterface={setModalInterface} setUserDetails={setUserDetails} userDetails={userDetails} pageDetails={pageDetails} setPageDetails={setPageDetails} setAppDetails={setAppDetails} /> : null}
          {pageDetails.gameSelect ? <ScreenGameSelect visible={pageDetails.gameSelect} setModalInterface={setModalInterface} setUserDetails={setUserDetails} userDetails={userDetails} pageDetails={pageDetails} setPageDetails={setPageDetails} setAppDetails={setAppDetails} /> : null }
          {pageDetails.settings ? <ScreenSettings visible={pageDetails.settings} setModalInterface={setModalInterface} userDetails={userDetails} setUserDetails={setUserDetails} pageDetails={pageDetails} setPageDetails={setPageDetails} /> : null}
          {pageDetails.gameDetails ? <ScreenGameDetails visible={pageDetails.gameDetails} setModalInterface={setModalInterface} userDetails={userDetails} appDetails={appDetails} setAppDetails={setAppDetails} pageDetails={pageDetails} setPageDetails={setPageDetails}/> : null}
          {pageDetails.gamePhase ? <ScreenGamePhase visible={pageDetails.gamePhase} setModalInterface={setModalInterface} userDetails={userDetails} appDetails={appDetails} setAppDetails={setAppDetails} pageDetails={pageDetails} setPageDetails={setPageDetails}/> : null}
          {pageDetails.admin ? <ScreenAdminPanel visible={pageDetails.admin} setModalInterface={setModalInterface} userDetails={userDetails} appDetails={appDetails} setAppDetails={setAppDetails} pageDetails={pageDetails} setPageDetails={setPageDetails} /> : null}
          <GenModal visible={modalInterface.visible} setModalInterface={setModalInterface} title={modalInterface.title} text={modalInterface.text} buttons={modalInterface.buttons} children={modalInterface.children}/>

          {userDetails.accessLevel === 5 && !pageDetails.admin ? <button className='admin-panel-button' onClick={() => nav.changeScreen(setPageDetails,"admin")}>Go To Admin Panel</button> : null}

    </div>
  );
}

export default App;
