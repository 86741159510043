import React from 'react'
import './PhaseSelector.css'
import { FaRegCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";


function PhaseSelector({paidPlan , ...props}) {

    function handleIcons(phase){
        
        switch(phase){
            case 1:
                if(props.appDetails.currentPhase === 1){
                    return <FaCircle onClick={() => changePhase(1)} size={150} />
                }
                else if(props.appDetails.currentPhase > 1) {
                    return <FaCheckCircle onClick={() => changePhase(1)} size={150} />
                }
            case 2: 
                if(props.appDetails.currentPhase === 2){
                    return <FaCircle onClick={() => changePhase(2)} size={150} />
                }
                else if(props.appDetails.currentPhase > 2){
                    return <FaCheckCircle onClick={() => changePhase(2)} size={150} />
                }
                else if(props.appDetails.currentPhase < 2){
                    return <FaRegCircle onClick={() => changePhase(2)} size={150} />
                }
            case 3: 
                if(props.appDetails.currentPhase === 3){
                    return <FaCircle onClick={() => changePhase(3)} size={150} />
                }
                else if(props.appDetails.currentPhase > 3){
                    return <FaCheckCircle onClick={() => changePhase(3)} size={150} />
                }
                else if(props.appDetails.currentPhase < 3){
                    return <FaRegCircle onClick={() => changePhase(3)} size={150} />
                }
            case 4: 
                if(props.appDetails.currentPhase === 4){
                    return <FaCircle onClick={() => changePhase(4)} size={150} />
                }
                else if(props.appDetails.currentPhase > 4){
                    return <FaCheckCircle onClick={() => changePhase(4)} size={150} />
                }
                else if(props.appDetails.currentPhase < 4){
                    return <FaRegCircle onClick={() => changePhase(4)} size={150} />
                }
            case 5: 
                if(props.appDetails.currentPhase === 5){
                    return <FaCircle onClick={() => changePhase(5)} size={150} />
                }
                else if(props.appDetails.currentPhase > 5){
                    return <FaCheckCircle onClick={() => changePhase(5)} size={150} />
                }
                else if(props.appDetails.currentPhase < 5){
                    return <FaRegCircle onClick={() => changePhase(5)} size={150} />
                }
            case 6: 
                if(props.appDetails.currentPhase === 6){
                    return <FaCircle onClick={() => changePhase(6)} size={150} />
                }
                else if(props.appDetails.currentPhase > 6){
                    return <FaCheckCircle onClick={() => changePhase(6)} size={150} />
                }
                else if(props.appDetails.currentPhase < 6){
                    return <FaRegCircle onClick={() => changePhase(6)} size={150} />
                }
        }

        function changePhase(phase){
            if(!paidPlan){
                if(phase > props.appDetails.currentPhase && phase > props.appDetails.maxPhase){
                    alert("Must complete previous phase to continue")
                    return
                }
            }

            props.setAppDetails(prev => ({
                ...prev,
                currentPhase: phase
            }))
        }
    }
  return (
    <div className='selector'>
        <h3>{props.appDetails.currentGame}</h3>
        <div className='selector-text-container'>
            <div className='selector-text'>
                <div>Settings</div>
            </div>
            <div className='selector-text'>
                <div>Music Assets</div>
            </div>
            <div className='selector-text'>
                <div>Game Assets</div>
            </div>
            <div className='selector-text'>
                <div>Confrimation</div>
            </div>
            <div className='selector-text'>
                <div>Payment</div>
            </div>
            <div className='selector-text'>
                <div>Overview</div>
            </div>
        </div>
        <div className='selector-line'>
            {handleIcons(1)}
            <hr></hr>
            {handleIcons(2)}
            <hr></hr>
            {handleIcons(3)}
            <hr></hr>
            {handleIcons(4)}
            <hr></hr>
            {handleIcons(5)}
            <hr></hr>
            {handleIcons(6)}
        </div>
        
    </div>
  )
}

export default PhaseSelector